
/* navbar positioning */
body {
  height: 100vh;
  padding: 0;
  margin: 0;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: #0f0f0f;
}
